import Lottie from 'lottie-react';

import contact from '../../lottie/contact.json';

function ContactFormAnimation() {
  return (
    <Lottie
      loop
      animationData={contact}
      className="w-52 mx-auto desktop:w-full mt-6"
    />
  );
}

export default ContactFormAnimation;
